import './btnApp.css';

import React, { useState, useEffect } from "react";
import { HiDownload } from "react-icons/hi";

function BtnApp() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      const handleBeforeInstallPrompt = (e) => {
        e.preventDefault(); // Impede que o prompt apareça automaticamente
        setDeferredPrompt(e); // Salva o evento
      };
  
      // Verifica se está no navegador, não no app instalado
      const isStandalone = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;
  
      // Exibe o botão apenas se o app não estiver instalado
      if (!isStandalone) {
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        setIsVisible(true); // Exibe o botão
      }
  
      return () => {
        window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
      };
    }, []);
  
    const handleInstallClick = async () => {
      if (deferredPrompt) {
        deferredPrompt.prompt(); // Mostra o prompt
        const { outcome } = await deferredPrompt.userChoice; // Espera pela escolha do usuário
        if (outcome === "accepted") {
          console.log("Usuário aceitou a instalação");
        } else {
          console.log("Usuário recusou a instalação");
        }
        setDeferredPrompt(null); // Reseta o prompt
        setIsVisible(false); // Esconde o botão
      }
    };
  
  return (
    <div className="install-app">
      
      {isVisible && (
        <>
            <p>Baixe nosso App</p>
            <button onClick={handleInstallClick}>
                <HiDownload />
            </button>
        </>
      )}
    </div>
  );
}

export default BtnApp;
