
import { onAuthStateChanged } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import { auth } from "../firebase/init";
import Loading from "../components/loading/loading";
import axios from "axios";

const authContext = React.createContext();

export function useAuth() {
    return useContext(authContext);
}

export function useAuthListener() {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Vai ser chamado sempre que o estado do usuário mudar 'false' || 'true'
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        });
        
        // limpa o listener
        return () => unsubscribe();
    }, []);

    return { currentUser, loading };
}

export function AuthProvider({children}) {

    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);
    
    return(
        <AuthProvider.Provider value={{ currentUser, loading }}>
            {!loading ? children : <Loading />}
        </AuthProvider.Provider>
    );
}

export const verifyEmailWithHunter = async (email) => {
    try {
      // Substitua 'sua_chave_api_do_hunter' pela chave gerada no Hunter
      const apiKey = '61ae94223165c36504a975118c974e99cdb9ee74'; // sua chave
  
      const response = await axios.get(`https://api.hunter.io/v2/email-verifier`, {
        params: {
          email: email,        // O email que você quer verificar
          api_key: apiKey,     // Sua chave de API
        },
      });
  
      // Verificando o resultado da verificação do email
      if (response.data.data.result === 'deliverable') {
        console.log('Email válido e entregável!');
        return true;
      } else {
        console.log('Email inválido ou não entregável!');
        return false;
      }
    } catch (error) {
      console.error('Erro ao verificar o email:', error.message);
      return false;
    }
  };