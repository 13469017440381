import "./serviceShow.css";
import { fetchUserData } from "../../../firebase/docs";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Loading from "../../../components/loading/loading";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const ServiceShow = () => {
    const { uuid } = useParams();
    const [userData, setUserData] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [infos, setInfos] = useState(false); // Índice do slide ativo

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchUserData(uuid);
                if (data) {
                    setUserData(data.userData);
                }
            } catch (err) {
                console.error("Erro ao buscar os dados:", err);
            }
        };

        fetchData();
    }, [uuid]);

    const handleInfos = (index) => {
        if (index === currentIndex) {
            setInfos((prev) => !prev);
        }
    };

    const handleNext = () => {
        if (userData && userData.servicos) {
            const totalSlides = Object.entries(userData.servicos).length;
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
        }
    };
    
    const handlePrev = () => {
        if (userData && userData.servicos) {
            const totalSlides = Object.entries(userData.servicos).length;
            setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
        }
    };

    return (
        <>
            <motion.div
                key="items__services"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3 }}
                className="item"
            >
                {userData ? (
                    <div className="item__container">
                        {userData?.servicos && Object.entries(userData.servicos).length > 0 ? (
                            <div className="item__slider" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                                {Object.entries(userData.servicos).map(([id, servico], index) => (
                                    <div
                                        className={`item__service ${index === currentIndex ? "active" : "inactive"}`}
                                        key={id}
                                    >
                                        <div className="infos__item__show">
                                            <h3 className="item__service__title">{servico.title}</h3>
                                            
                                        </div>
                                        <div className="item__arrow__left" onClick={handlePrev}>
                                            <IoIosArrowBack />
                                        </div>
                                        <img
                                            onClick={() => handleInfos(index)}
                                            className="item__image"
                                            src={servico.imgURL}
                                            alt={servico.title}
                                        />
                                        <div className="item__arrow__right" onClick={handleNext}>
                                            <IoIosArrowForward />
                                        </div>
                                        <div className="infos__item__show">
                                            <p className="item__service__desc">{servico.desc}</p>
                                            <p className="item__service__price">
                                                R$ <span>{servico.price}</span>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <Loading />
                        )}
                    </div>
                ) : (
                    <>
                        <Loading />
                    </>
                )}

                
            </motion.div>
        </>
    );
};

export default ServiceShow;
