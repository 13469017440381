import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth/cordova";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC1TRUTUukxGQX2G0AXAJ1u2WR3vtUVJRs",
  authDomain: "desenholy-gg.firebaseapp.com",
  databaseURL: "https://desenholy-gg-default-rtdb.firebaseio.com",
  projectId: "desenholy-gg",
  storageBucket: "desenholy-gg.appspot.com",
  messagingSenderId: "620560679033",
  appId: "1:620560679033:web:b171dde42dedbbc9ffd4a8",
  measurementId: "G-JJ98X0RR7G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };