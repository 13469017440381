import App from '../App';
import CompleteMessage from '../components/alerts/page/complete';
import PrivateRoute from '../contexts/privateContext';
import Bot from '../pages/botPage/bot';
import Ajuste from '../pages/login/private/ajuste/ajuste';
import Analise from '../pages/login/private/analise/analise';
import Clientes from '../pages/login/private/clientes/clientes';
import FormInfos from '../pages/login/private/form/form';
import Home from '../pages/login/private/home';
import Servicos from '../pages/login/private/servicos/servicos';
import Login from '../pages/login/login';
import Landing from '../landing/landing';
import About from '../landing/section/about/about';

const { BrowserRouter, Route, Routes } = require("react-router-dom");

const AppRoutes = () => {
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/entrar" element={<App />} />
                <Route path="/sobre" element={<About />} />
                <Route path="/registro-concluido" element={<CompleteMessage />} />
                <Route path="/:uuid" element={<Bot />} />
                <Route path="/perfil" element={<PrivateRoute element={<Home />} />}>
                    {/* Rotas internas aqui */}
                    <Route index element={<FormInfos />} />
                    <Route path="servicos" element={<Servicos />} />
                    <Route path="analises" element={<Analise />} />
                    <Route path="ajustes" element={<Ajuste />} />
                    <Route path="dados" element={<Clientes />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;