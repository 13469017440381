
import { useSpring, animated } from "@react-spring/web";
import "./hero.css";
import { ButtonLink } from "../../../components/button/button";

const Hero = () => {

    const springs = useSpring({
        from: { y: 100, opacity: 0 },
        to: { y: 0, opacity: 1 },
        config: { tension: 120, friction: 14 },
        delay: 200, // Atraso de 200ms
    });
    
    return (
        
        <section className="hero">
            
            <animated.div 
                style={{ 
                    ...springs
                }}
                className="hero__container">

                <div className="hero__title">
                    <h1>Transforme Suas Habilidades em Renda: <span>Crie e Compartilhe!</span></h1>
                </div>
                <div className="hero__subtitle">
                    <p>Simplificamos o processo para que você ofereça seus serviços de forma prática e automatizada. Crie, compartilhe e comece a atender!</p>
                </div>
                <div className="hero__cta">
                    <ButtonLink 
                        className="hero__create"
                        to="/entrar"
                        value="Crie sua Conta Agora"/>
                    <ButtonLink
                        className="hero__sobre"
                        to="sobre"
                        value="Saiba Mais" />
                </div>

            </animated.div>

        </section>

    );
}

export default Hero;