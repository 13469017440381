import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppRoutes from './routes/appRoutes';
import { HelmetProvider } from 'react-helmet-async';
// Importa o serviceWorker
import * as serviceWorker from './serviceWorker'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <AppRoutes />
  </HelmetProvider>
);

// Registra o service worker
serviceWorker.register();