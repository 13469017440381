import "./how.css";

import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";

import Image1 from "../../../assets/image/Tutorial 1.png";
import Image2 from "../../../assets/image/Tutorial 2.png";
import Image3 from "../../../assets/image/tutorial 3.png";
import { useSpring, animated } from "@react-spring/web";


const How = () => {

    const springs = useSpring({
        from: { y: 100, opacity: 0 },
        to: { y: 0, opacity: 1 },
        config: { tension: 120, friction: 14 },
        delay: 200, // Atraso de 200ms
    });

    return (
        <section className="how" id="como-usar">
            <animated.div
                style={{ 
                    ...springs
                }}
                className="how__anim">
                <h2>Como Funciona?</h2>
                <p>A forma de usar é bem simples e funcional.</p>
            </animated.div>
            <div className="steps">
                <div className="step">
                    <img src={Image1} alt="Etapa 1" />
                </div>
                <MdKeyboardArrowRight className="arrow-right"/>
                <MdKeyboardArrowDown className="arrow-down"/>
                <div className="step">
                    <img src={Image2} alt="Etapa 2" />
                </div>
                <MdKeyboardArrowRight className="arrow-right"/>
                <MdKeyboardArrowDown className="arrow-down"/>
                <div className="step">
                    <img src={Image3} alt="Etapa 3" />
                </div>
            </div>
        </section>
    );
}

export default How;