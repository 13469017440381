import './servicos.css';
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { motion } from "framer-motion";
import { BiSolidImageAlt } from "react-icons/bi";
import { FaPlusCircle } from 'react-icons/fa';

import { useAuthListener } from "../../../../contexts/contexts";
import { Button, ButtonLink } from "../../../../components/button/button";
import { uploadImage, doUpdateDocumentOnStorage, doGetDocumentOnStorage } from '../../../../firebase/docs';
import { useNavigate } from 'react-router-dom';
import Message from '../../../../components/alerts/message';
import Service from '../components/service/service';

const Servicos = () => {
    const { loading, currentUser } = useAuthListener();
    const navigate = useNavigate();

    const [newUpload, setNewUpload] = useState(false);
    const [btnAdd, setBtnAdd] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [alertStatus, setAlertStatus] = useState(null);
    const [hasService, setHasService] = useState(false);
    const [dataService, setDataService] = useState(null);

    const [formValues, setFormValues] = useState({
        title: "",
        desc: "",
        price: "",
        imgURL: ""
    });

    // Navegar para login se o usuário não estiver autenticado
    useEffect(() => {
        if (!loading && !currentUser) {
            navigate("/entrar");
        }
    }, [loading, currentUser, navigate]);

    // Verificar se todos os campos estão preenchidos
    useEffect(() => {
        setBtnAdd(formValues.title && formValues.desc && formValues.price);
    }, [formValues]);

    // Buscar dados dos serviços
    useEffect(() => {
        const fetchServices = async () => {
            try {
                const data = await doGetDocumentOnStorage();
                setDataService(data);
                setHasService(data && data.servicos && Object.keys(data.servicos).length > 0);
            } catch (error) {
                console.error("Erro ao buscar serviços:", error);
            }
        };
        fetchServices();
    }, []);

    // Atualizar a mensagem de alerta após 5 segundos
    useEffect(() => {
        if (alertStatus) {
            const timer = setTimeout(() => setAlertStatus(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [alertStatus]);

    const handleNewUpload = () => setNewUpload(true);

    const handleCancelUpload = () => {
        setNewUpload(false);
        resetForm();
    };

    const resetForm = () => {
        if (imagePreview) URL.revokeObjectURL(imagePreview);
        setFormValues({ title: "", desc: "", price: "", imgURL: "" });
        setImageFile(null);
        setImagePreview(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (imagePreview) URL.revokeObjectURL(imagePreview);
            setImageFile(file);
            setImagePreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!imageFile || !currentUser?.uid) {
            alert("Por favor, selecione uma imagem e preencha todos os campos.");
            return;
        }

        try {
            const imageUrl = await uploadImage(imageFile, `servicos/${currentUser.uid}/${imageFile.name}`);
            const updatedFormValues = { ...formValues, imgURL: imageUrl };

            const result = await doUpdateDocumentOnStorage(currentUser.uid, updatedFormValues);
            if (result) {
                setAlertStatus("alert-success");
                setNewUpload(false);
                resetForm();
            } else {
                setAlertStatus("alert-fail");
            }
        } catch (error) {
            console.error("Erro ao enviar serviço:", error);
            setAlertStatus("alert-fail");
        }
    };

    return (
        <div className="upload__container">
            <Helmet>
                <title>Desenholy - Serviços</title>
            </Helmet>

            {alertStatus && (
                <Message
                    className={`alert__message__fixed ${alertStatus}`}
                    title={alertStatus === "alert-success" ? "Novo Serviço Adicionado" : "Erro ao Adicionar Serviço"}
                    text={alertStatus === "alert-success" 
                        ? `${formValues.title} foi adicionado com sucesso!`
                        : "Ocorreu um erro. Tente novamente mais tarde."}
                />
            )}

            {!newUpload ? (
                <motion.div
                    className="up__btn upload__btn__add"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                >
                    {!hasService ? (
                        <div className="add__text">
                            <h1>Adicione seus primeiros produtos/Serviços</h1>
                            <h2>
                                O bot fará o restante. Apenas adicione seus{" "}
                                <span>produtos/serviços</span> para que ele possa oferecê-los aos clientes.
                            </h2>
                        </div>
                    ) : (
                        <Service />
                    )}
                    <ButtonLink
                        className="up__btn__add__new__service"
                        onClick={handleNewUpload}
                        value="Adicionar Novo Serviço"
                        icon={<FaPlusCircle />}
                    />
                </motion.div>
            ) : (
                <motion.div
                    className="up__btn upload__btn__new"
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                >
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="title"
                            value={formValues.title}
                            onChange={handleInputChange}
                            className='desc__product'
                            placeholder="Insira o nome do produto.."
                        />
                        <div className="upload__btn__new__fields">
                            <fieldset>
                                <label htmlFor="fileSelect">
                                    {imagePreview ? (
                                        <img src={imagePreview} alt="Preview" className="upload__image__preview" />
                                    ) : (
                                        <BiSolidImageAlt />
                                    )}
                                </label>
                                <input
                                    id="fileSelect"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </fieldset>
                            <textarea
                                name="desc"
                                value={formValues.desc}
                                onChange={handleInputChange}
                                placeholder="Descrição do produto/serviço"
                                maxLength={200}
                            />
                            <input
                                type="number"
                                name="price"
                                value={formValues.price}
                                onChange={handleInputChange}
                                placeholder="Preço"
                            />
                        </div>
                        <Button
                            type="submit"
                            className={btnAdd ? "enabled" : "disabled"}
                            value="Adicionar"
                            disabled={!btnAdd}
                        />
                    </form>
                    <ButtonLink
                        onClick={handleCancelUpload}
                        value="Cancelar"
                    />
                </motion.div>
            )}
        </div>
    );
};

export default Servicos;
