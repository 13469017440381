import "./inputs.css";

const Inputs = (prop) => {
    return <input className={`input ${prop.className}`}
                name        ={prop.name}
                id          ={prop.id}
                type        ={prop.type}
                placeholder ={prop.placeholder}
                value       ={prop.value}
                onChange    ={prop.onChange}/>
}

export default Inputs;