import "./clientes.css";
import { useEffect, useState } from "react";
import Loading from "../../../../components/loading/loading";
import { useAuthListener } from "../../../../contexts/contexts";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../../../firebase/init";
import { Helmet } from "react-helmet-async";
import BotLink from "../components/botLink";

const Clientes = () => {
    const { loading, currentUser } = useAuthListener();
    const [dataClient, setDataClient] = useState([]);
    const [activeMenu, setActiveMenu] = useState(null); 

    useEffect(() => {
        const fetchClients = async () => {
            if (!currentUser) return; // Garante que há um usuário logado
            try {
                // Crie a referência para a subcoleção de clientes
                const clientsRef = collection(db, 'users', currentUser.uid, 'clients');
                
                // Opcional: Se precisar filtrar ou ordenar, adicione um `query`
                const q = query(clientsRef);
                
                // Obtenha os documentos da subcoleção
                const querySnapshot = await getDocs(q);
                const clients = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
                // Armazene os clientes no estado
                setDataClient(clients);
            } catch (error) {
                console.error("Erro ao buscar clientes:", error.message);
            }
        };

        fetchClients();
    }, [currentUser]); // Recarrega sempre que o currentUser mudar

    if (loading) return <Loading />;

    const handleMenu = (clientId) => {
        setActiveMenu((prevId) => (prevId === clientId ? null : clientId)); // Alterna a visibilidade do menu
    };

    return (
        <div className="clients">
            <Helmet>
                <title>Meus clientes - Desenholy</title>
            </Helmet>
            
            {dataClient.length === 0 ? (
                <div className="clients__container">
                    <h2>Nenhum cliente cadastrado</h2>
                    <p>Para <span>facilitar</span> que seus clientes registre-se usando o bot-desenholy, copie e cole o link:</p> 
                    <BotLink className="clients__link"/> <p>e divulgue em suas redes sociais.</p>
                </div>
            ) : (
                <div className="clients__container">
                    <h2>Meus Clientes - <span>{dataClient.length}</span></h2>
                    {dataClient.map(client => (
                        <div className="clients__main">
                            <div 
                                className="clients__item"
                                key={client.id}>
                                <div className="clients__dv clients__dv__hover" onClick={() => handleMenu(client.id)}>
                                    <h3>{client.nome}</h3>
                                </div>
                                <div className={activeMenu === client.id ? "clients__dv" : "clients__dv__hide"}>
                                    <span>Whatsapp: </span> <p>{client.whatsapp}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Clientes;
