import './bot.css';

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from '../../components/loading/loading';
import { Helmet } from 'react-helmet-async';
import ChatFlow from './logic/chatFlow';
import { fetchUserData } from '../../firebase/docs';
import { FaWhatsapp } from "react-icons/fa";

const Bot = () => {

    const { uuid } = useParams();
    const [userData, setUserData] = useState(null);
    
    useEffect(() => {
      const fetchUser = async () => {
        try {
          const data = await fetchUserData(uuid);
          if (data) {
              setUserData(data.userData);
          }
        } catch (error) {
          console.error("Erro ao buscar os dados do usuário:", error);
        }
      };
  
      if (uuid) {
        fetchUser();
      }
    }, [uuid]);

    if (!userData) return <Loading />

    const handleWpp = async () => {
      try {
        const wpp = userData.telefoneSecundario;
    
        // Verifica se o telefone existe e contém apenas números
        if (wpp && /^\d+$/.test(wpp)) {
          const whatsappUrl = `https://wa.me/55${wpp}?text=${encodeURIComponent("Olá, gostaria de mais informações sobre os seus serviços!")}`;
          window.open(whatsappUrl, "_blank");
        } else {
          console.error("Número de telefone inválido:", wpp);
          alert("Número de telefone inválido. Por favor, tente novamente.");
        }
      } catch (error) {
        console.error("Erro ao tentar abrir o WhatsApp:", error);
      }
    };
    

    return (
      <div className='chat'>
        <Helmet>
          <title>
            {`Desenholy - ${userData.nomeEmpresa}`}
          </title>
        </Helmet>
        {userData ? (
          <div className='chat__flex'>
            <fieldset>
              <p><span>Horário de Atendimento: <br /> </span>{userData.horarioFuncionamento}</p> <br />
              <p><span>Local: </span>{userData.endereco.rua}, {userData.endereco.numero}</p>
            </fieldset>

          </div>
        ) : (
          <p>Carregando dados do usuário...</p>
        )}
        {userData ? (
          <div className='chat__container'>

            <div className='chat__top'>
              <h2>{userData.nomeEmpresa}</h2>
            </div>
            
            <div className='chat__mid'>
                <ChatFlow />
            </div>

          </div>
        ) : (
          <p>Carregando dados do usuário...</p>
        )}
        {userData ? (
          <div className='chat__flex'>
            <button onClick={handleWpp} className='whatsapp'>Fale Conosco <FaWhatsapp /></button>
          </div>
        ) : (
          <p>Carregando dados do usuário...</p>
        )}
      </div>
    );
}

export default Bot;