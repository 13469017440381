import "./message.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Importa o hook useParams

import { motion } from "framer-motion";
import { RiSendPlane2Fill } from "react-icons/ri";
import { getDocNameFromUuid, saveClientData } from "../../../firebase/docs";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/init";
import Message from "../../../components/alerts/message";
import { getUnicode } from "emoji-dictionary";

const MessageChat = () => {
    // Pega o uuid da URL
    const { uuid } = useParams();
    const [uid, setUid] = useState(null);
    const [userData, setUserData] = useState(null); // userData não parece estar sendo usado
    const [whatsapp, setWhatsapp] = useState("");
    const [nome, setNome] = useState("");
    const [enviado, setEnviado] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const docName = await getDocNameFromUuid(uuid);
                setUid(docName); // Define o UID logo após obter o nome do documento

                const userDocRef = doc(db, "users", docName);
                const userDocSnapshot = await getDoc(userDocRef);

                if (userDocSnapshot.exists()) {
                    console.log("Usuário encontrado:", userDocSnapshot.data());
                    setUserData(userDocSnapshot.data());
                } else {
                    console.error("Usuário não encontrado!");
                }
            } catch (error) {
                console.error("Erro ao buscar os dados do usuário:", error);
            }
        };

        if (uuid) {
            fetchUserData();
        }
    }, [uuid]); // Remover dependência de `uid` aqui, pois já estamos definindo o UID de forma independente

    const handleInputSubmit = () => {
        if (nome && whatsapp && uid) { // Garantir que todos os campos estão preenchidos
            // Quando ambos os campos estiverem preenchidos, salva os dados
            saveClientData(uid, { nome, whatsapp });
            setEnviado(true);
        } else {
            alert("Por favor, preencha todos os campos.");
        }
    };

    return (
        <div className="message">
            {/* Mensagem de sucesso */}
            {enviado ? (
                <Message className="alert-success" title={`Concluído ${getUnicode('smile')}`} text="Você se cadastrou na nossa loja com sucesso! Continue para ver nossos serviços e continuar seu atendimento." />
            ) : (
                <>
                    {/* Coleta do Nome */}
                    <motion.div
                        className="message__input"
                        initial={{ scale: 0, y: 20 }}
                        animate={{ scale: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <input
                            type="text"
                            value={nome}
                            className="message__input__insert"
                            onChange={(e) => setNome(e.target.value)} // Atualiza o nome
                            placeholder="Digite seu Nome Completo"
                        />
                    </motion.div>

                    {/* Coleta do WhatsApp */}
                    <motion.div
                        className="message__input"
                        initial={{ scale: 0, y: 20 }}
                        animate={{ scale: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <input
                            type="text"
                            value={whatsapp}
                            className="message__input__insert"
                            onChange={(e) => setWhatsapp(e.target.value)} // Atualiza o WhatsApp
                            placeholder="Digite seu WhatsApp"
                        />
                    </motion.div>

                    {/* Botão de enviar */}
                    <motion.div
                        className="message__input"
                        initial={{ scale: 0, y: 20 }}
                        animate={{ scale: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <button
                            className="message__input__submit"
                            onClick={handleInputSubmit} // Chama handleInputSubmit para salvar os dados
                            title="Enviar"
                        >
                            <RiSendPlane2Fill />
                        </button>
                    </motion.div>
                </>
            )}
        </div>
    );
};

export default MessageChat;
