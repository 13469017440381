import { ScrollLink } from "react-scroll";
import { ButtonLink } from "../../../components/button/button";

const About = () => {
    return (
        <section className="about">
            <div className="a__left">
                <ButtonLink 
                    value="Voltar"
                    to="/" />
                
            </div>
            <div className="a__right"></div>
        </section>
    );
}

export default About;