import "./header.css";
import { useLocation, useNavigate } from "react-router-dom";
import { doSignOut } from "../../../../../firebase/auth";
import { ButtonLink } from "../../../../../components/button/button";

import BotLink from "../botLink";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BiMenu } from "react-icons/bi";
import { IoColorFill } from "react-icons/io5";
import { GoWorkflow } from "react-icons/go";
import { FaUser } from "react-icons/fa6";
import { IoIosExit } from "react-icons/io";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../../../firebase/init";
import Loading from "../../../../../components/loading/loading";
import { MdDesignServices, MdInsertDriveFile } from "react-icons/md";

const Header = () => {
    const [drop, setDrop] = useState(false);
    const [activeButton, setActiveButton] = useState("");

    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
            if(!currentUser) {
                navigate("/entrar");
            }
        });
        return () => unsubscribe();
    }, [navigate]);
    
    useEffect(() => {
        const route = path.split("/").pop();
        setActiveButton(route || "perfil"); 
    }, [path]);

    const handleSignOut = async () => {
        const quit = window.confirm("Você deseja mesmo sair?");
        if (!quit) return;

        try {
            await doSignOut();
            navigate("/entrar");
        } catch (error) {
            console.error("Erro ao sair:", error.code);
        }
    };

    const handleDropdownToggle = () => setDrop((prev) => !prev);

    const dropdownVariants = {
        closed: {
            height: 0,
            opacity: 0,
            overflow: "hidden",
            transition: { duration: 0.3, ease: "easeInOut" }
        },
        open: {
            height: "auto",
            opacity: 1,
            overflow: "hidden",
            transition: { duration: 0.3, ease: "easeInOut" }
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <header>
            {/* Lado Esquerdo */}
            <div className="header__lmr header__left">
                <BotLink className="header__btn" />
            </div>

            {/* Centro */}
            <div className="header__lmr header__middle">
                {/* Espaço reservado para elementos futuros */}
            </div>

            <div className="header__lmr header__right">
                {/* Botão Dropdown */}

                <div className="menu__header">
                    {/* Meu Perfil */}
                    <ButtonLink
                        className={`option__menu ${activeButton === "perfil" ? "header__btn--disabled" : ""}`}
                        to="/perfil"
                        onClick={activeButton === "perfil" ? undefined : handleDropdownToggle}
                        value="Perfil"
                        icon={<FaUser />}
                    />

                    {/* Meu Fluxo */}
                    <ButtonLink
                        className={`option__menu ${activeButton === "fluxo" ? "header__btn--disabled" : ""}`}
                        to="/perfil/fluxo"
                        onClick={activeButton === "fluxo" ? undefined : handleDropdownToggle}
                        value="Fluxo"
                        icon={<GoWorkflow />}
                    />

                    {/* Dados Listados */}
                    <ButtonLink
                        className={`option__menu ${activeButton === "dados" ? "header__btn--disabled" : ""}`}
                        to="/perfil/dados"
                        onClick={activeButton === "dados" ? undefined : handleDropdownToggle}
                        value="Dados"
                        icon={<MdInsertDriveFile />}
                    />

                    {/* Serviços */}
                    <ButtonLink
                        className={`option__menu ${activeButton === "servicos" ? "header__btn--disabled" : ""}`}
                        to="/perfil/servicos"
                        onClick={activeButton === "servicos" ? undefined : handleDropdownToggle}
                        value="Serviços"
                        icon={<MdDesignServices />}
                    />

                    {/* Personalização */}
                    <ButtonLink
                        className={`option__menu ${activeButton === "aparencia" ? "header__btn--disabled" : ""}`}
                        to="/perfil/aparencia"
                        onClick={activeButton === "aparencia" ? undefined : handleDropdownToggle}
                        value="Aparência"
                        icon={<IoColorFill />}
                    />

                    {/* Sair */}
                    <ButtonLink
                        className="option__menu"
                        to="/"
                        onClick={handleSignOut}
                        value="Sair"
                        icon={<IoIosExit />} />
                        
                </div>

                {/*  */}
                {/* DROPDOWN AQUI ABAIXO */}
                {/*  */}

                <div className="menu__dropdown">
                    <button
                        className="dropmenu"
                        onClick={handleDropdownToggle}
                        aria-expanded={drop}
                        aria-label="Abrir Menu">
                        <BiMenu />
                    </button>

                    {/* Menu Dropdown */}
                    <motion.div
                        className="dropdown"
                        initial="closed"
                        animate={drop ? "open" : "closed"}
                        variants={dropdownVariants}
                    >
                        {/* Meu Perfil */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "perfil" ? "header__btn--disabled" : ""}`}
                            to="/perfil"
                            onClick={activeButton === "perfil" ? undefined : handleDropdownToggle}
                            value="Perfil"
                            icon={<FaUser />}
                        />

                        {/* Meu Fluxo */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "fluxo" ? "header__btn--disabled" : ""}`}
                            to="/perfil/fluxo"
                            onClick={activeButton === "fluxo" ? undefined : handleDropdownToggle}
                            value="Fluxo"
                            icon={<GoWorkflow />}
                        />

                        {/* Dados Listados */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "dados" ? "header__btn--disabled" : ""}`}
                            to="/perfil/dados"
                            onClick={activeButton === "dados" ? undefined : handleDropdownToggle}
                            value="Dados"
                            icon={<MdInsertDriveFile />}
                        />

                        {/* Serviços */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "servicos" ? "header__btn--disabled" : ""}`}
                            to="/perfil/servicos"
                            onClick={activeButton === "servicos" ? undefined : handleDropdownToggle}
                            value="Serviços"
                            icon={<MdDesignServices />}
                        />

                        {/* Personalização */}
                        <ButtonLink
                            className={`option__menu ${activeButton === "aparencia" ? "header__btn--disabled" : ""}`}
                            to="/perfil/aparencia"
                            onClick={activeButton === "aparencia" ? undefined : handleDropdownToggle}
                            value="Aparência"
                            icon={<IoColorFill />}
                        />

                        {/* Sair */}
                        <ButtonLink
                            className="option__menu"
                            to="/"
                            onClick={handleSignOut}
                            value="Sair"
                            icon={<IoIosExit />}
                        />
                    </motion.div>
                </div>
            </div>
        </header>
    );
};

export default Header;
