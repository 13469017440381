import "./home.css";

import Loading from "../../../components/loading/loading";
import Header from "./components/header/header";
import FormInfos from "./form/form";

import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthListener } from "../../../contexts/contexts";
import { useEffect, useState } from "react";
import { ButtonLink } from "../../../components/button/button";
import { FaListAlt } from "react-icons/fa";
import { IoPeopleSharp } from "react-icons/io5";

export default function Home(){
    const { currentUser, loading } = useAuthListener();

    const [btnServicos, setBtnServicos] = useState(false);
    const [btnAnalises, setBtnAnalises] = useState(false);
    const [btnAjustes, setBtnAjustes] = useState(false);
    const [btnClientes, setBtnClientes] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();
    const path = location.pathname;

    

    useEffect(() => {
        if (!loading && !currentUser) {
            navigate("/");
        }
        
    }, [loading, currentUser, navigate]);

    if (loading) {
        return <Loading />;
    }

    return (
        <section className="container">

            <div className="container__lmr container__left">
                <Header/>
            </div>
            
            <div className="container__middle">
                <Outlet />
            </div>

            <div className="container__lmr container__right">

            </div>
        </section>
    );
}