import './App.css';
import BtnApp from './btnApp';
import Login from './pages/login/login';

function App() {

  

  return (
    <div className="App">
      <Login />
      <BtnApp />
    </div>
  );
}

export default App;
