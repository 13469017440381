import "./info.css"

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchUserData } from "../../../firebase/docs";
import Loading from "../../../components/loading/loading";

const Info = () => {

    const { uuid } = useParams();
    const [userData, setUserData] = useState(null);
    
    useEffect(() => {
        const fetchUser = async () => {
          try {
            const data = await fetchUserData(uuid);
            if (data) {
                setUserData(data.userData);
            }
          } catch (error) {
            console.error("Erro ao buscar os dados do usuário:", error);
          }
        };
    
        if (uuid) {
          fetchUser();
        }
      }, [uuid]);
  
      if (!userData) return <Loading />

    return (
        <div className="infos__emp">
            <p>Empresa fundada no ano de <span>{userData.anoFundacao}</span></p>
            <p>Localiza na cidade de <span>{userData.endereco.cidade}</span></p>
            <p>Reponsável: <span>{userData.responsavel.nomeCompleto}</span></p>
        </div>
    );
}

export default Info;