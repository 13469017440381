import $ from "jquery";
import "jquery-mask-plugin";
import { useEffect, useMemo, useRef, useState } from "react";
import "./form.css";
import { useAuthListener } from "../../../../contexts/contexts";
import { doGetDocumentOnStorage, doUpdateForm } from "../../../../firebase/docs";
import { Button, ButtonLink } from "../../../../components/button/button";
import Message from "../../../../components/alerts/message";
import { Helmet } from "react-helmet-async";
import { getUnicode } from "emoji-dictionary";
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { RiEdit2Fill } from "react-icons/ri";

const FormInfos = () => {

    const {currentUser} = useAuthListener();
    const [userEmail, setUserEmail] = useState('');
    const [uuid, setUuid] = useState('');
    const [txtCopy, setTxtCopy] = useState('');

    const [isSubmited, setIsSubmited] = useState(false);
    const [alertStatus, setAlertStatus] = useState(null);
    
    const [active, setActive] = useState(true);
    const [send, setSend] = useState(false);
    

    const refs = useRef({});

    // Memoize masks to ensure its reference remains stable
    const masks = useMemo(
        () => ({
        cpf: "000.000.000-00",
        telefone: "(00)0000-0000",
        wpp: "00000000000",
        cnpj: "00.000.000/0000-00",
        cep: "00000-000",
        }), []);

        useEffect(() => {
            Object.keys(masks).forEach((key) => {
                if (refs.current[key]) {
                    $(refs.current[key]).mask(masks[key]);
                }
            });

            return () => {
                Object.keys(masks).forEach((key) => {
                    if (refs.current[key]) {
                        $(refs.current[key]).unmask();
                    }
                });
        };
    }, [masks]);

    const [formValues, setFormValues] = useState({
        // Informações Básicas da Empresa 
        nomeEmpresa: "",
        cnpj: "",
        inscricaoEstadual: "",
        ramoAtividade: "",
        anoFundacao: "",
        tipoEmpresa: "",
        numeroFuncionarios: 0,
    
        // Informações de Contato
        telefonePrincipal: "",
        telefoneSecundario: "",
        website: "",
        linkedin: "",
    
        // Endereço da Sede
        endereco: {
            rua: "",
            numero: 123,  // ajustável para valor padrão
            complemento: "",
            bairro: "",
            cidade: "",
            estado: "",
            cep: ""
        },
    
        // Dados do Responsável Legal
        responsavel: {
            nomeCompleto: "",
            cpf: "",
            cargo: "",
            telefoneContato: "",
        },
    
        // Informações Bancárias
        banco: {
            nomeBanco: "",
            agencia: "",
            conta: "",
            cnpjCpfTitular: ""
        },
    
        // Dados de Funcionamento
        horarioFuncionamento: "",
        diasFuncionamento: ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"],
        abreEmFeriados: false,
    
        // Redes Sociais
        redesSociais: {
            facebook: "",
            instagram: "",
        },
    
        // Informações de Serviços/Produtos
        produtosServicos: "",
        descricaoEmpresa: "",
        areaAtendimento: "",
    
        // Faturamento Anual e Informações Financeiras
        faturamentoAnual: "",
        possuiInvestimentoExterno: false,
        detalhesFinanceiros: "",
    
        // Outros Campos
        observacoesAdicionais: ""
    });

    useEffect(() => {
        if (!currentUser) return;

        const firestore = getFirestore(); 
        const usersRef = collection(firestore, 'users'); 
        const q = query(usersRef, where('uid', '==', currentUser.uid)); 

        getDocs(q).then((querySnapshot) => {
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    
                    setUuid(doc.id);
                    setTxtCopy(`localhost:3000/${doc.id}`); 
                });
            } else {
                console.log("Nenhum usuário encontrado.");
            }
        }).catch((error) => {
            console.error("Erro ao buscar o usuário:", error);
        });

    }, [currentUser]);

    const handleEditInput = (e) => {
        e.preventDefault();
        setActive(false);
        setIsSubmited(false);

    }

    const handleSaveInput = (e) => {
        setActive(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const telefone = formValues.telefoneSecundario.replace(/\D/g, '');

        if (telefone.length === 11) {
            // Enviar para o servidor
        } else {
            alert("O número de telefone precisa ter exatamente 11 dígitos.");
        }
        
        try {
            // Chame a função de atualização
            const updateDocs = await doUpdateForm(uuid, formValues);
    
            // Verifique se o valor muda corretamente
    
            if (updateDocs) {
                setSend(true);
                setAlertStatus("alert-success");
            } else {
                setSend(false);
                setAlertStatus("alert-fail");
            }
    
            setIsSubmited(true); // Defina isSubmited como true após o processo de envio
    
        } catch (err) {
            console.error("Erro ao atualizar documento:", err.message);
            setSend(false);
            setAlertStatus("alert-fail");
            setIsSubmited(true); // Mesmo no erro, defina isSubmited como true
        }
    
        // Resetar estado após 8 segundos
        setTimeout(() => {
            setSend(false);
            setIsSubmited(false);
            setAlertStatus(null);
        }, 8000);
    };

    useEffect(() => {
        
        const fetchUserDoc = async () => {
            if (currentUser?.uid) {
                const data = await doGetDocumentOnStorage(uuid);
                if (data) {
                    setFormValues({
                        // Informações Básicas da Empresa
                        uuid: data.uuid,
                        nomeEmpresa: data.nomeEmpresa || "",
                        cnpj: data.cnpj || "",
                        inscricaoEstadual: data.inscricaoEstadual || "",
                        ramoAtividade: data.ramoAtividade || "",
                        anoFundacao: data.anoFundacao || "",
                        tipoEmpresa: data.tipoEmpresa || "",
                        numeroFuncionarios: data.numeroFuncionarios || 0,
                    
                        // Informações de Contato
                        telefonePrincipal: data.telefonePrincipal || "",
                        telefoneSecundario: data.telefoneSecundario || "",
                        emailContato: data.emailContato || "",
                        website: data.website || "",
                        linkedin: data.linkedin || "",
                    
                        // Endereço da Sede
                        endereco: {
                            rua: data.endereco?.rua || "",
                            numero: data.endereco?.numero || 123,
                            complemento: data.endereco?.complemento || "",
                            bairro: data.endereco?.bairro || "",
                            cidade: data.endereco?.cidade || "",
                            estado: data.endereco?.estado || "",
                            cep: data.endereco?.cep || ""
                        },
                    
                        // Dados do Responsável Legal
                        responsavel: {
                            nomeCompleto: data.responsavel?.nomeCompleto || "",
                            cpf: data.responsavel?.cpf || "",
                            cargo: data.responsavel?.cargo || "",
                            telefoneContato: data.responsavel?.telefoneContato || "",
                            email: data.responsavel?.email
                        },
                    
                        // Informações Bancárias
                        banco: {
                            nomeBanco: data.banco?.nomeBanco || "",
                            agencia: data.banco?.agencia || "",
                            conta: data.banco?.conta || "",
                            cnpjCpfTitular: data.banco?.cnpjCpfTitular || ""
                        },
                    
                        // Dados de Funcionamento
                        horarioFuncionamento: data.horarioFuncionamento || "",
                        diasFuncionamento: data.diasFuncionamento || ["Segunda", "Terça", "Quarta", "Quinta", "Sexta"],
                        abreEmFeriados: data.abreEmFeriados || false,
                    
                        // Redes Sociais
                        redesSociais: {
                            facebook: data.redesSociais?.facebook || "",
                            instagram: data.redesSociais?.instagram || "",
                            twitter: data.redesSociais?.twitter || "",
                            youtube: data.redesSociais?.youtube || "",
                            outros: data.redesSociais?.outros || [""]
                        },
                    
                        // Informações de Serviços/Produtos
                        produtosServicos: data.produtosServicos || "",
                        descricaoEmpresa: data.descricaoEmpresa || "",
                        areaAtendimento: data.areaAtendimento || "",
                    
                        // Faturamento Anual e Informações Financeiras
                        faturamentoAnual: data.faturamentoAnual || "",
                        possuiInvestimentoExterno: data.possuiInvestimentoExterno || false,
                        detalhesFinanceiros: data.detalhesFinanceiros || "",
                    
                        // Outros Campos
                        observacoesAdicionais: data.observacoesAdicionais || "Outras observações."
                    });
                    
                }
                else if(data && data.responsavel && data.responsavel.email) {
                    setUserEmail(data.responsavel.email);
                }
            }
        };
    
        fetchUserDoc();
    }, [currentUser?.uid, uuid]);

    const handleCepChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, '');
        
        const formattedCep = rawValue.replace(/^(\d{5})(\d{3})$/, '$1-$2');
    
        setFormValues({
            ...formValues,
            endereco: { ...formValues.endereco, cep: formattedCep }
        });
    };
    
    return (
        <form className="form" onSubmit={handleSubmit}>
            <Helmet>
                <title>
					{`Desenholy - Perfil`}
				</title>
            </Helmet>

            {isSubmited && alertStatus && send ? (
                <fieldset className="field__alerts">
                    <Message
                        className={`alert__message__fixed ${alertStatus}`}
                        title={
                            alertStatus === "alert-success"
                                ? "Dados Atualizados"
                                : "Algo deu errado"
                        }
                        text={
                            alertStatus === "alert-success"
                                ? `Seus dados foram atualizados com sucesso. ${getUnicode("smile")}`
                                : `Seus dados não foram salvos. Tente novamente mais tarde ${getUnicode("sob")}`
                        }
                    />
                </fieldset>
            ) : null}

            <div className="title__ui">
                <h1 className="title__ui__title">
                    Desen<span>holy</span>
                </h1>
                <p className="title__ui__text">
                    Preencha as informações corretamente para que o seu bot funcione como o esperado.
                </p>
            </div>

            {/* Informações Básicas da Empresa */}
            <fieldset className="form__section form__section--empresa">
                <legend className="form__section-title">Informações Principais</legend>

                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="nomeEmpresa">Nome da Empresa <i>(*)</i></label>
                        <input 
                            className="form__input form__input--nome-empresa" 
                            type="text" 
                            id="nomeEmpresa" 
                            value={formValues.nomeEmpresa} 
                            onChange={(e) => setFormValues({ ...formValues, nomeEmpresa: e.target.value })} 
                            disabled={active} 
                            placeholder="Digite o nome da sua empresa" 
                        />
                    </div>
                    <div className="form__section__divide">
                        <label htmlFor="anoFundacao">Ano de Fundação </label>
                        <input 
                            className="form__input form__input--ano-fundacao" 
                            type="text" 
                            id="anoFundacao" 
                            value={formValues.anoFundacao} 
                            onChange={(e) => setFormValues({ ...formValues, anoFundacao: e.target.value })} 
                            disabled={active} 
                            placeholder="Ano de Fundação" 
                        />
                    </div>
                </div>

                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="tipoEmpresa">Tipo de Empresa </label>
                        <input 
                            className="form__input form__input--tipo-empresa" 
                            type="text" 
                            id="tipoEmpresa" 
                            value={formValues.tipoEmpresa} 
                            onChange={(e) => setFormValues({ ...formValues, tipoEmpresa: e.target.value })} 
                            disabled={active} 
                            placeholder="Tipo de Empresa" 
                        />
                    </div>
                    <div className="form__section__divide">
                        <label htmlFor="numeroFuncionarios">Número de Funcionários</label>
                        <input 
                            className="form__input form__input--numero-funcionarios" 
                            type="number" 
                            id="numeroFuncionarios" 
                            value={formValues.numeroFuncionarios} 
                            onChange={(e) => setFormValues({ ...formValues, numeroFuncionarios: e.target.value })} 
                            disabled={active} 
                            placeholder="Número de Funcionários" 
                        />
                    </div>
                </div>

                
                <label htmlFor="website">Website</label>
                <input 
                    className="form__input form__input--website" 
                    type="text" 
                    id="website" 
                    value={formValues.website} 
                    onChange={(e) => setFormValues({ ...formValues, website: e.target.value })} 
                    disabled={active} 
                    placeholder="Digite seu website" 
                />

                <label>Horário de Funcionamento</label>
                <input 
                    className="form__input form__input--horario" 
                    type="text" 
                    disabled={active} 
                    value={formValues.horarioFuncionamento}
                    onChange={(e) => setFormValues({
                        ...formValues,
                        horarioFuncionamento: e.target.value
                    })}
                    placeholder="Descreva os horários de atendimento na sua empresa" />

            </fieldset>

            {/* Informações de Contato */}
            <fieldset className="form__section form__section--contato">
                <legend className="form__section-title">Informações de Contato</legend>

                <label htmlFor="emailContato">E-mail de Contato <i>(*)</i></label>
                <input 
                    className="form__input form__input--email-contato" 
                    type="email" 
                    id="emailContato" 
                    value={formValues.emailContato}
                    onChange={(e) => setFormValues({
                        ...formValues,
                        emailContato: e.target.value
                    })}
                    disabled
                    placeholder="Digite o e-mail que gostaria de receber mensagens" 
                />

                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="telefoneSecundario">WhatsApp</label>
                        <input 
                            className="form__input form__input--telefone-secundario" 
                            ref={(el) => (refs.current.wpp = el)}
                            type="text"
                            id="telefoneSecundario" 
                            value={formValues.telefoneSecundario}
                            onChange={(e) => setFormValues({ ...formValues, telefoneSecundario: e.target.value })} 
                            disabled={active}
                            placeholder="Digite o telefone com o whatsApp"
                        />
                    </div>
                    <div className="form__section__divide">
                        <label htmlFor="telefonePrincipal">Telefone Principal</label>
                        <input 
                            className="form__input form__input--telefone-principal" 
                            ref={(el) => (refs.current.telefone = el)}
                            type="text" 
                            id="telefonePrincipal" 
                            value={formValues.telefonePrincipal} 
                            onChange={(e) => setFormValues({ ...formValues, telefonePrincipal: e.target.value })} 
                            disabled={active} 
                            placeholder="Telefone Principal" 
                        />
                    </div>
                </div>

                
            </fieldset>

            {/* Endereço da Sede */}
            <fieldset className="form__section form__section--endereco">
                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="cep">CEP </label>
                        <input 
                            className="form__input form__input--cep" 
                            type="text" 
                            id="cep"
                            value={formValues.endereco.cep} 
                            onChange={handleCepChange} 
                            disabled={active}
                            placeholder="CEP" 
                        />
                    </div>
                    <div className="form__section__divide">
                        
                        <label htmlFor="numero">Número </label>
                        <input 
                            className="form__input form__input--numero"
                            type="number" 
                            id="numero" 
                            value={formValues.endereco.numero} 
                            onChange={(e) => setFormValues({ ...formValues, endereco: { ...formValues.endereco, numero: e.target.value } })} 
                            disabled={active} 
                            placeholder="Número" 
                        />
                        
                    </div>
                    
                </div>


                <div className="form__section__div">
                    <div className="form__section__divide">
                        <label htmlFor="cidade">Cidade </label>
                        <input 
                            className="form__input form__input--cidade" 
                            type="text" 
                            id="cidade" 
                            value={formValues.endereco.cidade} 
                            onChange={(e) => setFormValues({ ...formValues, endereco: { ...formValues.endereco, cidade: e.target.value } })} 
                            disabled={active} 
                            placeholder="Cidade"/>
                    </div>
                    <div className="form__section__divide">
                        
                        <label htmlFor="bairro">Bairro </label>
                        <input 
                            className="form__input form__input--bairro" 
                            type="text" 
                            id="bairro" 
                            value={formValues.endereco.bairro} 
                            onChange={(e) => setFormValues({ ...formValues, endereco: { ...formValues.endereco, bairro: e.target.value } })} 
                            disabled={active} 
                            placeholder="Bairro"/>
                    </div>
                </div>

                <legend className="form__section-title">Endereço da Sede</legend>

                <label htmlFor="rua">Rua <i>(opcional)</i></label>
                <input 
                    className="form__input form__input--rua" 
                    type="text" 
                    id="rua" 
                    value={formValues.endereco.rua} 
                    onChange={(e) => setFormValues({
                        ...formValues,
                        endereco: {
                            ...formValues.endereco, rua: e.target.value
                        }
                    })} 
                    disabled={active} 
                    placeholder="Rua" 
                />

                <label htmlFor="complemento">Complemento </label>
                <input 
                    className="form__input form__input--complemento" 
                    type="text" 
                    id="complemento" 
                    value={formValues.endereco.complemento} 
                    onChange={(e) => setFormValues({ ...formValues, endereco: { ...formValues.endereco, complemento: e.target.value } })} 
                    disabled={active} 
                    placeholder="Complemento" 
                />

                <label htmlFor="estado">Estado </label>
                <input 
                    className="form__input form__input--estado" 
                    type="text" 
                    id="estado" 
                    value={formValues.endereco.estado} 
                    onChange={(e) => setFormValues({ ...formValues, endereco: { ...formValues.endereco, estado: e.target.value } })} 
                    disabled={active} 
                    placeholder="Estado" 
                />

            </fieldset>

            {/* Dados do Responsável Legal */}
            <fieldset className="form__section form__section--responsavel">

                <div className="form__section__div">
                    <div className="form__section__divide">
                        
                        <label>Cargo <i>(*)</i></label>
                        <input 
                            className="form__input form__input--cargo" 
                            type="text" 
                            value={formValues.responsavel.cargo} 
                            onChange={(e) => setFormValues({ 
                                ...formValues, 
                                responsavel: { ...formValues.responsavel, cargo: e.target.value } 
                            })} 
                            disabled={active} 
                            placeholder="Cargo"/>

                    </div>
                </div>

                <legend className="form__section-title">Dados do Responsável Legal</legend>

                <label>Nome Completo <i>(*)</i></label>
                <input 
                    className="form__input form__input--nome-completo" 
                    type="text" 
                    value={formValues.responsavel.nomeCompleto} 
                    onChange={(e) => setFormValues({ 
                        ...formValues, 
                        responsavel: { ...formValues.responsavel, nomeCompleto: e.target.value } 
                    })} 
                    disabled={active} 
                    placeholder="Nome Completo" 
                />

                <label>E-mail do Responsável <i>(*)</i></label>
                <input 
                    className="form__input form__input--email-responsavel" 
                    type="email" 
                    value={formValues.responsavel.email} 
                    onChange={(e) => setFormValues({ 
                        ...formValues, 
                        responsavel: { 
                            ...formValues.responsavel, 
                            emailResponsavel: e.target.value 
                        } 
                    })}
                    disabled={active} 
                    placeholder="E-mail do Responsável" 
                />
            </fieldset>

            {/* Redes Sociais */}
            <fieldset className="form__section form__section--redes-sociais">

                <legend className="form__section-title">Redes Sociais</legend>

                <div className="form__section__div">
                    <div className="form__section__divide">
                        
                        <label>Facebook</label>
                        <input 
                        className="form__input form__input--facebook" 
                        type="text" 
                        disabled={active}
                        value={formValues.redesSociais.facebook}
                        onChange={(e) => setFormValues({
                            ...formValues,
                            redesSociais: {
                                ...formValues.redesSociais,
                                facebook: e.target.value
                            }
                        })}
                        placeholder="Facebook" />

                    </div>
                    <div className="form__section__divide">
                        
                        <label>Instagram</label>
                        <input 
                            className="form__input form__input--instagram" 
                            type="text" 
                            disabled={active}
                            value={formValues.redesSociais.instagram}
                            onChange={(e) => setFormValues({
                                ...formValues,
                                redesSociais: {
                                    ...formValues.redesSociais,
                                    instagram: e.target.value
                                }
                            })}
                            placeholder="Instagram" />
                        
                    </div>
                </div>
            
            </fieldset>

            {/* Observações Adicionais */}
            <fieldset className="form__section form__section--observacoes">
                <legend className="form__section-title">Observações Adicionais</legend>
                <label>Outras observações</label>
                <textarea 
                    className="form__input form__input--observacoes-adicionais" 
                    disabled={active} 
                    value={formValues.observacoesAdicionais}
                    onChange={(e) => setFormValues({
                        ...formValues,
                        observacoesAdicionais: e.target.value
                    })}
                    placeholder="Descreva detalhes sobre você/seu negócio caso queira detalhar algo.(opcional)" />
            </fieldset>

            <fieldset className="form__section form__section--buttons">
    
                {!active ? 
                    <Button 
                        className="form__section__save"
                        onClick={handleSaveInput}
                        value="Salvar"/>
                : 
                    <ButtonLink
                        icon={<RiEdit2Fill />}
                        className="form__section__edit"
                        onClick={handleEditInput}
                        value="Editar"/>
                }

                
            </fieldset>

            

        </form>
    );
}

export default FormInfos;
