import './service.css';
import { useEffect, useState } from "react";
import { doDeleteService, doGetAllServices, doUpdateService, uploadImage } from "../../../../../firebase/docs";
import { Button } from '../../../../../components/button/button';

import { MdEdit } from "react-icons/md";
import { TiDelete } from "react-icons/ti";
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { useAuthListener } from '../../../../../contexts/contexts';
import { BiSolidImageAlt } from 'react-icons/bi';

const Service = () => {
    const {currentUser} = useAuthListener();
    const [services, setServices] = useState({});
    const [loading, setLoading] = useState(true);
    const [imagePreview, setImagePreview] = useState(null);

    // Para armazenar o ID do serviço a ser editado
    const [editServiceId, setEditServiceId] = useState({ price: "" });

    const handleInputChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres que não são números
        const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        }).format(rawValue / 100); // Formata o número como decimal com duas casas
        setEditServiceId({ ...editServiceId, price: formattedValue });
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const nameFile = file.name;
        if (!file) return;
    
        try {
            // Aqui você faz o upload do arquivo
            const imageUrl = await uploadImage(file, `servicos/${currentUser.uid}/${nameFile}`);
            setImagePreview(URL.createObjectURL(file))
            // Atualiza o estado do serviço em edição com a nova URL da imagem
            setEditServiceId((prev) => ({
                ...prev,
                imgURL: imageUrl,
            }));
    
        } catch (error) {
            console.error("Erro ao fazer upload da imagem:", error.message);
            alert("Erro ao fazer upload da imagem.");
        }
    };

    useEffect(() => {
        const loadServices = async () => {
            const data = await doGetAllServices();
            setServices(data);
            setLoading(false);
            setEditServiceId(null);
            setImagePreview(null);
        };

        loadServices();
    }, []);

    if (loading) return <p>Carregando serviços...</p>;

    const serviceKeys = Object.keys(services);

    if (serviceKeys.length === 0) {
        return <p>Nenhum serviço encontrado.</p>;
    }

    const handleEdit = (serviceId) => {
        const serviceToEdit = services[serviceId];
        setEditServiceId({ ...serviceToEdit, id: serviceId });
        setImagePreview(null);
    };

    const handleSave = async (e) => {
        e.preventDefault();
    
        try {
            // Atualiza o serviço no backend
            await doUpdateService(editServiceId.id, editServiceId);
    
            // Atualiza o estado local com os dados editados
            setServices((prev) => ({
                ...prev,
                [editServiceId.id]: { ...editServiceId }
            }));
    
            alert("Serviço atualizado com sucesso!");
            setEditServiceId(null); // Fecha o formulário
            setImagePreview(null);
        } catch (error) {
            console.error("Erro ao atualizar o serviço:", error.message);
            alert("Erro ao salvar alterações.");
        }
    };

    const handleDelete = async (serviceId) => {
        const confirmDelete = window.confirm("Tem certeza que deseja excluir este serviço?");
        if (!confirmDelete) return;
    
        try {
            await doDeleteService(serviceId);
    
            // Atualiza o estado local para remover o serviço excluído
            setServices((prev) => {
                const updatedServices = { ...prev };
                delete updatedServices[serviceId];
                return updatedServices;
            });
    
            alert("Serviço excluído com sucesso!");
        } catch (error) {
            console.error("Erro ao excluir o serviço:", error.message);
            alert("Erro ao excluir o serviço.");
        }
    };

    return (
        <div className='service'>
            <h1>Lista de Serviços</h1>
            <br /> <br/>
            <div className='service__grid'>
                
                {serviceKeys.map((serviceId) => {
                    const service = services[serviceId];
                    const isEditing = editServiceId?.id === serviceId; // Verifica se este serviço está sendo editado

                    return (
                        <div className='service__item' key={serviceId}>
                            
                            <div className='service__img'>
                                
                                {isEditing ? (
                                    <div className='service__style'>
                                        <label htmlFor="fileSelect" className="upload__icon__label">
                                            {imagePreview ? (
                                                <img
                                                    src={imagePreview} // Mostra a imagem de pré-visualização
                                                    alt="Pré-visualização da imagem"
                                                    className="upload__image__preview"
                                                />
                                            ) : (
                                                editServiceId.imgURL && (
                                                    <img
                                                        src={editServiceId.imgURL} // Mostra a imagem existente
                                                        alt={`Imagem de ${editServiceId.title}`}
                                                        className="upload__image__preview"
                                                    />
                                                )
                                            )}
                                        </label>
                                        <input
                                            id="fileSelect"
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            className="upload__file__input"
                                        />
                                    </div>
                                ) : (
                                    service.imgURL && (
                                        <img
                                            src={service.imgURL}
                                            alt={`Imagem de ${service.title}`}
                                        />
                                    )
                                )}
                                
                            </div>
                            
                            <div className='service__info'>
                                {/* Campo de título */}
                                {isEditing ? (
                                    <input
                                        className="input__title"
                                        type="text"
                                        value={editServiceId.title}
                                        onChange={(e) => setEditServiceId({ ...editServiceId, title: e.target.value })}
                                    />
                                ) : (
                                    <h2>{service.title}</h2>
                                )}

                                {/* Campo de descrição */}
                                {isEditing ? (
                                    <textarea
                                        className="input__desc"
                                        value={editServiceId.desc}
                                        onChange={(e) => setEditServiceId({ ...editServiceId, desc: e.target.value })}
                                    />
                                ) : (
                                    <p className='service__desc'>{service.desc}</p>
                                )}

                                {/* Campo de preço */}
                                {isEditing ? (
                                    <div className="service__price__edit">
                                        R$ <input
                                                type="text"
                                                className="input__price"
                                                value={editServiceId.price}
                                                onChange={(e) => {
                                                    const rawValue = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                                                    const formattedValue = new Intl.NumberFormat("pt-BR", {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }).format(rawValue / 100);
                                                    setEditServiceId({ ...editServiceId, price: formattedValue });
                                                }}
                                            />
                                    </div>
                                ) : (
                                    <p className='service__price'>R$ <span>{service.price}</span></p>
                                )}
                            </div>
                            
                            <div className='service__edit'>

                                {/* Alterna entre salvar e editar */}
                                {isEditing ? (
                                    <div className='service__edit--ui'>
                                        <Button 
                                            className="service__edit--edit--btn service__edit--save"
                                            value={<FaCheckCircle />}
                                            onClick={handleSave}
                                        />
                                        <Button 
                                            className="service__edit--edit--btn service__edit--cancel"
                                            value={<MdCancel />}
                                            onClick={() => setEditServiceId(null)} 
                                        />
                                    </div>
                                ) : (
                                    <>
                                        
                                        <Button 
                                            className="service__edit--btn"
                                            value={<MdEdit />}
                                            onClick={() => handleEdit(serviceId)} 
                                        />

                                        <Button 
                                            className="service__edit--btn"
                                            value={<TiDelete />}
                                            onClick={() => handleDelete(serviceId)}
                                            />
                                        
                                    </>
                                )}
                            </div>

                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Service;
