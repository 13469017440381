import "./footer.css"

import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { RiInstagramFill, RiDiscordFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";


export default function Footer() {
    return (
        <footer className="footer">
            <div className="f-dv">
                <div className="f-container">
                    <div className="social">
                        <h3>Entre em contato conosco nas redes sociais</h3>
                        <Link to="https://www.instagram.com/desenholy" target="_blank"><RiInstagramFill /></Link>
                        <Link to="https://discord.gg/vd9SxtGzwb" target="_blank"><RiDiscordFill /></Link>
                    </div>
                </div>
            </div>
            <div className="m-dv">
            
                <div className="f-container">
                    <div className="f-grid">
                        <h2 className="brand"> Desen<span>holy</span> </h2>
                        <p> Nosso objetivo é <b>aprimorar e elevar as artes visuais</b> dos nossos clientes, proporcionando soluções criativas que atendam às suas necessidades e superem expectativas.</p>
                    </div>
                    <div className="f-grid">
                        <h3>Navegar</h3>
                        <div>
                            <ScrollLink to="header" smooth={true} duration={200}>Voltar ao inicio</ScrollLink>
                            <ScrollLink to="como-usar" smooth={true} duration={200}>Como Usar</ScrollLink>
                            <Link to="entrar">Efetuar o login</Link>
                        </div>
                    </div>
                    <div className="f-grid">
                        <h3>Dúvida?</h3>
                        <div>
                            <Link to="mailto:atendimento@desenholy.com">Reportar falha</Link>
                            <Link to="mailto:atendimento@desenholy.com">Ajuda</Link>
                        </div>
                    </div>
                    <div className="f-grid">
                        <h3>Entre em contato</h3>
                        <div>
                            <Link to="mailto:contato@desenholy.com"> <MdEmail /> contato@desenholy.com </Link>
                            <Link to="https://www.instagram.com/desenholy" target="_blank"> <RiInstagramFill /> @desenholy</Link>
                            <p>Mande sua mensagem, será respondia o mais rápido possivel.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="l-dv"> 
                <div className="f-container">
                    <p>
                        <Link to="https://www.instagram.com/pleed.is" target="_blank">@pleed.is</Link> | Todos os direitos reservados &copy; 2024
                    </p>
                </div>
            </div>
        </footer>
    );
}