import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase/init";
import Loading from "../../../components/loading/loading";
import { getDocNameFromUuid } from "../../../firebase/docs"; // Supondo que essa função obtenha o docName correto
import Flow from "../components/flow";

const ChatFlow = () => {
    const { uuid } = useParams();
    const [userData, setUserData] = useState(null);
    const [uid, setUid] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
          try {
            const docName = await getDocNameFromUuid(uuid);
            setUid(docName);
            const usersCollection = collection(db, "users");
            
            // Busca pelo campo `uuid` dentro dos documentos
            const q = query(usersCollection, where("uuid", "==", uuid));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
              const userDoc = querySnapshot.docs[0].data();
              setUserData(userDoc);
            } else {
              console.error("Usuário não encontrado!");
            }
          } catch (error) {
            console.error("Erro ao buscar os dados do usuário:", error);
          }
        };
    
        if (uuid) {
          fetchUserData();
        }
      }, [uuid]);

    if (!userData || !uid) return <Loading />; // Espera até que `userData` e `uid` estejam disponíveis

    return (
        <Flow />
    );
};

export default ChatFlow;
