import { useLocation, useNavigate } from "react-router-dom";
import "./completeMessage.css";
import { motion } from "framer-motion";
import { useEffect } from "react";

export default function CompleteMessage() {

    const location = useLocation();
    const navigate = useNavigate();
    const { tag_email } = location.state || {};

    useEffect(() => {
        const hasShowMessage = localStorage.getItem('hasShowMessage');

        if(hasShowMessage) {
            navigate('/');
        } else {
            localStorage.getItem('hasShowMessage', 'true');
        }

    }, [navigate]);

    const handleClick = () => {
        navigate('/');
    }
    
    return (
        <div className="complete">

        <motion.div
            className="complete__message"
            key     ="formRegister"
            initial ={{ opacity: 0, scale: 0 }}
            animate ={{ opacity: 1, scale: 1 }}
            exit    ={{ opacity: 0, scale: 0 }}>
            
            <b>{tag_email}</b>
            <h2>Concluído com <span>sucesso!</span></h2>
            <p>Siga enfrente e desfrute das funcionalidades</p>
            <button className="button" onClick={handleClick}>Continuar</button>
            

        </motion.div>

        </div>
    );
}