import "./header.css";
import { ButtonLink } from "../../../components/button/button";
import { BiMenu } from "react-icons/bi";
import { useState } from "react";
import { motion } from "framer-motion";
import { Link as ScrollLink } from "react-scroll";

const Header = () => {
  const [drop, setDrop] = useState(false);

  const handleDropdownToggle = () => setDrop((prev) => !prev);

  const dropdownVariants = {
    closed: {
      height: 0,
      opacity: 0,
      overflow: "hidden",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
    open: {
      height: "auto",
      opacity: 1,
      overflow: "hidden",
      transition: { duration: 0.3, ease: "easeInOut" },
    },
  };

  return (
    <header className="header">
      <div className="head">
        <div className="head__item">
          <h2>
            Desen<span>holy</span>
          </h2>
        </div>
        <div className="head__item">
          <nav className="menu">
            <ScrollLink to="como-usar" smooth={true} duration={200}>Como Usar</ScrollLink>
            <ButtonLink to="/entrar" value="Minha Conta" />
          </nav>
          <div className="menu__dropdown">
            <button
              className="dropmenu"
              onClick={handleDropdownToggle}
              aria-expanded={drop}
              aria-label="Abrir Menu">
              <BiMenu />
            </button>
            {/* Menu Dropdown */}
            <motion.div
              className="dropdown"
              initial="closed"
              animate={drop ? "open" : "closed"}
              variants={dropdownVariants}
            >
              <ButtonLink to="como-usar" value="Como Usar" />
              <ButtonLink to="entrar" value="Minha Conta" />
            </motion.div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
