import { useState } from "react";
import { Button } from "../../components/button/button";
import Inputs from "../../components/inputs/inputs";
import { doCreateUserWithEmailAndPassword } from "../../firebase/auth";
import { useNavigate } from "react-router-dom";
import { verifyEmailWithHunter } from "../../contexts/contexts";

export default function RegisterForm() {

    // Estado dos campos de registro
    const [emailReg, setEmailReg] = useState('');
    const [passReg, setPassReg] = useState('');
    const [rePassReg, setRePassReg] = useState('');
    
    const navigate = useNavigate();

    const [errors, setErrors] = useState({ email: '', password: '', rePassword: '' });

    // Função para verificar a validade da senha
    const passwordValid = (password) => {
        const hasMinLength = password.length >= 6;
        const hasSpecialChar = /[!@#$%¨&*()_+-=^`´{}~}º/?|]/.test(password);

        if (!hasMinLength) {
            return "A senha deve conter 6 ou mais caracteres";
        } else if (!hasSpecialChar) {
            return "A senha tem que ter pelo menos um caracter especial";
        }
        return null; // Senha válida
    };

    // Validação dos campos
    const validate = () => {
        let emailError = '';
        let passError = '';
        let rePassError = '';
        let equalPass = passReg === rePassReg;

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,}$/.test(emailReg);

        if (!emailReg) {
            emailError = 'O e-mail é obrigatório!';
        } else if (!emailRegex) {
            emailError = 'Insira um e-mail válido!';
        }

        if (!equalPass) {
            rePassError = 'As senhas não coincidem';
        }

        passError = passwordValid(passReg);

        setErrors({ email: emailError, password: passError, rePassword: rePassError });

        return !emailError && !passError && !rePassError;
    };

    // Função para registro
    const handleSubmitRegister = async (e) => {
        e.preventDefault();
        const isValid = validate();
        console.log(errors);

        if (isValid) {
            const isEmailValid = await verifyEmailWithHunter(emailReg); // Chama a função de verificação do email

            if (!isEmailValid) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: 'Verifique se esse email está certo',
                }));
                return; // Se o email não for válido, não prossegue com o registro
            }

            try {
                await doCreateUserWithEmailAndPassword(emailReg, passReg);
                navigate('/registro-concluido', { state: { tag_email: emailReg } });

            } catch (err) {
                console.log(err.code);
                if (err.code === "auth/email-already-in-use") {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: "Esse email já está cadastrado"
                    }));
                } else {
                    console.log(err.code);
                }
            }
        }
    };

    return (
        <form className="register" onSubmit={handleSubmitRegister} method="POST">
            <h2>Criar <span>Conta</span></h2>
            <div className="register__input">
                <Inputs 
                    id="email"
                    name="email"
                    className="forms__input"
                    type="email"
                    placeholder="Digite seu email"
                    value={emailReg}
                    onChange={(e) => setEmailReg(e.target.value)}
                />
                {errors.email && <span>{errors.email}</span>}
            </div>
            <div className="register__input">
                <Inputs 
                    className="forms__input"
                    type="password"
                    placeholder="Crie sua senha"
                    value={passReg}
                    onChange={(e) => setPassReg(e.target.value)}
                />
                {errors.password && <span>{errors.password}</span>}
            </div>
            <div className="register__input">
                <Inputs 
                    className="forms__input"
                    type="password"
                    placeholder="Repita sua senha"
                    value={rePassReg}
                    onChange={(e) => setRePassReg(e.target.value)}
                />
                {errors.rePassword && <span>{errors.rePassword}</span>}
            </div>
            <Button 
                className="register__btn"
                value="Criar Conta"/>
        </form>
    );
}
