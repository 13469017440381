import "./divide.css";

const Divide = (prop) => {
    return (
        <div className="divide" id="header">
            <div className="divide__container">
                {prop.text}
                {prop.icon}
            </div>
        </div>
    );
}

export default Divide;