import './button.css';
import { Link } from 'react-router-dom';


const Button = (prop) => {
    return (
        <button 
            className={`button ${prop.className}`}
            onClick={prop.onClick}
            disabled={prop.disabled}>
            {prop.value}
        </button>
    );
}

const ButtonLink = (prop) => {
    const shortenedValue = prop.value.length > 30 ? `${prop.value.slice(0, 30)}...` : prop.value;

    return (
        <Link 
            className={`button-link ${prop.className}`}
            onClick={prop.onClick}
            to={prop.to}
            >
            <b>{shortenedValue}</b>{prop.icon} {/* Usando o valor encurtado como texto do botão */}
        </Link>
    );
}


export { Button, ButtonLink };