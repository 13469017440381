import { collection, getDocs, query, where } from "firebase/firestore";
import { getDocNameFromUuid } from "../../../firebase/docs";
import { db } from "../../../firebase/init";
import "./message.css";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Loading from "../../../components/loading/loading";
import { useParams } from "react-router-dom";
import MessageChat from "./message";
import ServiceShow from "./serviceShow";
import Info from "./info";

const Flow = () => {
    const { uuid } = useParams();
    const [userData, setUserData] = useState(null);
    const [currentStep, setCurrentStep] = useState("start");
    const [uid, setUid] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const docName = await getDocNameFromUuid(uuid);
                setUid(docName);
                const usersCollection = collection(db, "users");

                const q = query(usersCollection, where("uuid", "==", uuid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0].data();
                    setUserData(userDoc);
                } else {
                    console.error("Usuário não encontrado!");
                }
            } catch (error) {
                console.error("Erro ao buscar os dados do usuário:", error);
            }
        };

        if (uuid) {
            fetchUserData();
        }
    }, [uuid]);

    if (!userData || !uid) return <Loading />;

    const infoChat = {
        start: {
            message: `Olá, seja bem-vindo(a)! Você é novo aqui na ${userData.nomeEmpresa}?`,
            options: [
                { text: "Sim, sou novo aqui!", next: "novoUsuario" },
                { text: "Não, já sou cliente!", next: "cliente" },
            ],
        },
        novoUsuario: {
            message: "Poderia me informar seu nome e número de whatsapp para que possamos manter um melhor contato?",
            options: [
                { text: "Quero apenas ver os serviços.", next: "servicos" },
            ]
        },
        servicos: {
            message: "Aqui estão os nossos principais serviços",
            options: [
                { text: "Quero saber mais sobre a empresa", next: "info" }
            ]
        },
        cliente: {
            message: "Que bom que está aqui. Como posso te ajudar?",
            options: [
                { text: "Quero saber mais sobre a empresa", next: "info" },
                { text: "Ver Serviços", next: "servicos" },
            ],
        },
        info: {
            message: `Sobre ${userData.nomeEmpresa}`,
            options: [
                { text: "Voltar ao ínicio", next: "start" },
            ],
        },
    };

    const handleOptionClick = (nextStep) => {
        setCurrentStep(nextStep);
    };

    const handleSubmitMessage = () => {
        if (currentStep === "novoUsuario") {
            setCurrentStep("servicos"); // Após o envio, avança para a próxima etapa
        }
        // Adicionar lógica para outros casos, caso necessário
    };

    const currentMessage = infoChat[currentStep];

    return (
        <motion.div
            className="message"
            key="flowMessage"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3 }}
        >
            {/* Mensagem principal */}
            <div className="message__sended">
                <p>{currentMessage.message}</p>
            </div>

            {/* Componentes condicionalmente renderizados */}
            {currentStep === "novoUsuario" && <MessageChat />}
            {currentStep === "servicos" && <ServiceShow />} 
            {currentStep === "info" && <Info />}

            {/* Botões de opções */}
            <div className="message__options">
                {currentMessage.options &&
                    currentMessage.options.map((option, index) => (
                        <button
                            key={index}
                            onClick={() => handleOptionClick(option.next)}>
                            {option.text}
                        </button>
                    ))}
            </div>
        </motion.div>
    );
};

export default Flow;
