import { useNavigate } from "react-router-dom";
import Loading from "../components/loading/loading";
import { useAuthListener } from "./contexts"

const PrivateRoute = ({element}) => {
    const { currentUser, loading } = useAuthListener();
    const navigete = useNavigate();
    if (loading) {
        return <Loading />;
    }

    return currentUser ? element: navigete('/');
}

export default PrivateRoute;