import './loading.css';
import image from '../../assets/logo/Logo-500.png';

const Loading = ({prop}) => {
    return  <div className="loading"> 
                <img src={image} alt='Carregando' width={100} height={100}/> 
                <span>Carregando, aguarde..</span>
            </div>
}

export default Loading;